import * as React from 'react';
// import Link from 'next/link';
import styled from 'styled-components';
// import { Close32 } from '@carbon/icons-react';
// import { SIDEBAR_WIDTH } from 'components/blocks/Sidebar/styles';
// import useBrowserStorage from 'hooks/useBrowserStorage';
import { mobile } from 'styles/breakpoints';
import { pageWidthStyles } from 'styles/elements';

import { wrapperStyles } from './styles';

// const BANNER_KEY = '@@ifinance/disable-banner';

interface Props {
  right?: boolean;
  sidebar?: boolean;
}

const BannerWrapper = styled.div<{ sidebar?: boolean; right?: boolean; isStatic?: boolean }>`
  ${wrapperStyles};
  height: auto;
  background-color: ${({ theme }) => theme.color.buttonPrimary};
  padding: 12px 0;
  padding-left: 12px;
  position: relative;

  ${mobile()} {
    padding: 8px 12px;
  }
`;

const BannerContent = styled.div`
  ${pageWidthStyles};
  height: 100%;
`;

const CourseStatus = styled.div`
  line-height: 1.425;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 100%;
  font-weight: 600;

  ${mobile()} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Banner: React.FC<React.PropsWithChildren<Props>> = ({ right, sidebar }) => {
  // const [storedBannerState, setStoredBannerState] = useBrowserStorage(BANNER_KEY, 0);
  // const [isOpen, setIsOpen] = React.useState(false);

  // React.useEffect(() => {
  //   if (!storedBannerState) {
  //     setIsOpen(true);
  //   }
  // }, [storedBannerState]);

  // if (!isOpen) {
  //   return null;
  // }

  return (
    <BannerWrapper right={right} sidebar={sidebar}>
      <BannerContent>
        <CourseStatus>
          {/* <Link href="/course" passHref>
            <PrelaunchText>Notification</PrelaunchText>
          </Link>{' '} */}
          Real-time dashboard launch offer ending soon: lock in our founder's pricing indefinitely
          for a limited time
        </CourseStatus>
        {/* <CloseButton
          aria-label="Close banner"
          onClick={() => {
            setIsOpen(false);
            setStoredBannerState(Date.now());
          }}
        >
          <Close32 />
        </CloseButton> */}
      </BannerContent>
    </BannerWrapper>
  );
};

export default Banner;
