import { useMemo } from 'react';
import useViewer from 'hooks/useViewer';
import {
  hasDashboardAccess,
  hasCompletedOpraAgreement as hasCompletedOpraAgreementCheck,
  hasAlertAccess as hasAlertAccessCheck,
} from 'services/client/token';
import { AuthStatus } from 'constants/auth';

export const useHasAccess = () => {
  const viewer = useViewer();

  const access = useMemo(() => {
    const isLoadingAuth = viewer.status === AuthStatus.Loading;
    const isUser = viewer.status === AuthStatus.User;
    const isAnonymous = viewer.status === AuthStatus.Anonymous;
    const shouldHaveFree =
      viewer.status === AuthStatus.Anonymous ||
      (viewer.status === AuthStatus.User && !hasDashboardAccess(viewer));
    const shouldHaveDashboardAccess =
      viewer.status === AuthStatus.User && hasDashboardAccess(viewer);
    const hasCompletedOpraAgreement =
      viewer.status === AuthStatus.User && hasCompletedOpraAgreementCheck(viewer);
    const hasAlertAccess = viewer.status === AuthStatus.User && hasAlertAccessCheck(viewer);

    return {
      isLoadingAuth,
      isUser,
      isAnonymous,
      shouldHaveFree,
      shouldHaveDashboardAccess,
      hasCompletedOpraAgreement,
      hasAlertAccess,
    };
  }, [viewer.status]);

  return access;
};
