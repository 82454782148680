import { keyframes } from 'styled-components';

export const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const fadeInLeft = (scoreWidth: number) => keyframes`
  from { width: 0; opacity: 0; }
  to { width: ${scoreWidth}px; opacity: 1; }
`;

export const slideInRow = keyframes`
  from { transform: translateX(-12px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
`;

export const removeHighlight = (props: any) => keyframes`
  from { background-color: ${props.theme.color.buttonPrimary}; }
  to { background-color: transparent; }
`;
