import styled from 'styled-components';
import { mobile } from 'styles/breakpoints';

export const Meter = styled.div`
  border-radius: 6px;
  line-height: 1;
`;

export const MeterFill = styled.div<{ width: number }>`
  width: ${({ width }) => width}%;
  background: ${({ theme }) => theme.color.graphCall};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: ${({ width }) => (width === 100 ? 8 : 0)}px;
  border-bottom-right-radius: ${({ width }) => (width === 100 ? 8 : 0)}px;
`;

export const MeterEmpty = styled.div<{ width: number }>`
  width: ${({ width }) => width}%;
  background: ${({ theme }) => theme.color.textMuted};
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: ${({ width }) => (width === 100 ? 8 : 0)}px;
  border-bottom-left-radius: ${({ width }) => (width === 100 ? 8 : 0)}px;
`;

export const CompetitorTitle = styled.div`
  font-size: 16px;

  ${mobile()} {
    font-size: 10px;
  }
`;

export const RowTitle = styled.div`
  font-size: 16px;

  ${mobile()} {
    font-size: 10px;
  }
`;
