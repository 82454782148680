import React, { Fragment } from 'react';
import Link from 'components/Link';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon, ChevronDownIcon } from '@heroicons/react/outline';
import { uniqueId } from 'lodash';
import useViewer from 'hooks/useViewer';
import { SIGNUP_PAGE, LOGIN_PAGE, OPTION_FLOW_PAGE, HOME_PAGE } from 'constants/pages';
import classNames from 'styles/utils/classNames';
import SlideoutMenu from './SlideoutMenu';
import { useModal } from 'hooks/useModal';

import LogoImage from 'svg/logo';

interface Navitem {
  name: string;
  href: string;
  current: Boolean;
  subMenu?: Navitem[];
}
interface Props {
  navitems: Navitem[];
  className?: string;
  classNameBackground?: string;
  fullwidth?: boolean;
  isNavBordered?: boolean;
}
export default function NavBar({ navitems, classNameBackground, fullwidth, isNavBordered }: Props) {
  const viewer = useViewer();
  const { isOpen, openModal, closeModal } = useModal();

  return (
    <>
      <nav
        className={classNames(
          'mx-auto w-full flex-1 px-4 backdrop-blur-md',
          isNavBordered && 'border-b border-palette-border',
          classNameBackground ? classNameBackground : 'bg-palette-black-1/95',
        )}
      >
        <div
          className={classNames(
            'relative mx-auto flex h-[4.5rem] items-center justify-between md:h-14',
            fullwidth ? 'w-full px-[2%]' : 'max-w-7xl',
          )}
        >
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex flex-1 flex-shrink-0 items-center">
              <Link href={HOME_PAGE} passHref key={uniqueId()} className="justify-center">
                <LogoImage className="h-5 lg:h-8" />
              </Link>
            </div>
            <div className="hidden flex-auto lg:ml-6 lg:block">
              <div className="flex items-center justify-end space-x-4">
                {navitems.map((item, i) =>
                  item?.subMenu ? (
                    <Popover key={i} as="div" className="relative">
                      {({ close, open }) => (
                        <>
                          <Popover.Button className="styled-font inline-flex items-center gap-x-1 px-1 text-base font-bold leading-6 focus-visible:outline-none">
                            <div key={i}>{item.name}</div>
                            {item?.subMenu && (
                              <ChevronDownIcon
                                className={classNames(
                                  'h-4 w-4 transition-transform',
                                  open && 'rotate-180 transform',
                                )}
                                aria-hidden="true"
                              />
                            )}
                          </Popover.Button>
                          {item.subMenu && (
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-1"
                            >
                              <Popover.Panel className="absolute left-1/2 z-10 mt-2 flex w-screen max-w-max -translate-x-1/2 px-4">
                                <div className="w-full flex-auto rounded-2xl bg-palette-black-4 text-sm leading-6 ">
                                  <div className="flex flex-col" onClick={() => close()}>
                                    {item?.subMenu?.map((subItem, i) => {
                                      return (
                                        <Link
                                          href={subItem.href}
                                          passHref
                                          key={i}
                                          className={classNames(
                                            'styled-font bg-palette-black-4 px-6 py-4 text-base font-bold capitalize text-white transition-colors visited:text-white hover:bg-palette-black-5',
                                            i === 0 && 'rounded-t-2xl',
                                            i === (item?.subMenu?.length || 0) - 1 &&
                                              'rounded-b-2xl',
                                          )}
                                        >
                                          {subItem.name}
                                        </Link>
                                      );
                                    })}
                                  </div>
                                </div>
                              </Popover.Panel>
                            </Transition>
                          )}
                        </>
                      )}
                    </Popover>
                  ) : (
                    <Link
                      className="styled-font inline-flex items-center gap-x-1 px-1 text-base font-bold leading-6 focus-visible:outline-none"
                      href={item.href}
                      key={i}
                      isExternal={item.href.startsWith('http')}
                    >
                      {item.name}
                    </Link>
                  ),
                )}
                {!viewer.viewer ? (
                  <>
                    <Link
                      href={LOGIN_PAGE}
                      className="min-w-[5rem] rounded border-2 border-brand-primary bg-transparent px-3 py-1.5 text-center text-sm font-bold transition-colors hover:bg-brand-primary"
                    >
                      Log In
                    </Link>
                    <Link
                      href={SIGNUP_PAGE}
                      className="min-w-[5rem] rounded border-2 border-brand-primary bg-brand-primary px-3 py-1.5 text-center text-sm font-bold transition-colors hover:border-white hover:bg-white hover:text-brand-primary"
                    >
                      Sign Up
                    </Link>
                  </>
                ) : (
                  <Link
                    href={OPTION_FLOW_PAGE}
                    className="min-w-[5rem] rounded border-2 border-brand-primary bg-brand-primary px-3 py-1.5 text-center text-sm font-bold transition-colors hover:border-white hover:bg-white hover:text-brand-primary"
                  >
                    Dashboard
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center lg:hidden">
            <button
              onClick={() => openModal()}
              className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Open main menu</span>
              {isOpen ? (
                <XIcon className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <MenuIcon className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </nav>
      <SlideoutMenu sidebarOpen={isOpen} setSidebarOpen={closeModal} />
    </>
  );
}
