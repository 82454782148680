import * as React from 'react';
import { Meter, MeterFill, MeterEmpty, RowTitle, CompetitorTitle } from './styles';

const COMPETITOR_HEADERS = ['', 'InsiderFinance', 'Competitor 1', 'Competitor 2'];
const COMPETITOR_ROWS = [
  { name: 'Actionable Data', if: 100, c1: 90, c2: 80 },
  { name: 'Trade Ideas', if: 90, c1: 60, c2: 80 },
  { name: 'Analytical Power', if: 90, c1: 80, c2: 70 },
  { name: 'Advanced Visuals', if: 95, c1: 75, c2: 75 },
  { name: 'Ease of Use', if: 90, c1: 80, c2: 90 },
  { name: 'Value', if: 100, c1: 55, c2: 80 },
  { name: 'Technical Analysis', if: 100, c1: 0, c2: 0 },
];

export default function CompetitorComparison() {
  return (
    <div>
      <div className="text-center">
        <h2 className="styled-font text-2xl font-semibold">
          How InsiderFinance compares to other trading tools
        </h2>
        <p className="mx-auto mt-2 max-w-3xl text-sm text-gray-400">
          InsiderFinance is the complete toolkit for traders. Our philosophy is to create the tools
          that make understanding and analyzing financial data dead-simple to use. See how we
          compare to our competitors.
        </p>
      </div>
      <div className="mt-8 rounded-xl border border-gray-800 p-4 text-xs md:py-6 md:px-8 md:text-base">
        <div className="mb-4 grid grid-cols-4 gap-2 border-b border-gray-800 pb-4 leading-none md:mb-6 md:gap-6 md:pb-6">
          {COMPETITOR_HEADERS.map((h, i) => (
            <CompetitorTitle key={i} className="styled-font">
              {h}
            </CompetitorTitle>
          ))}
        </div>
        <div className="grid grid-cols-4 gap-2 md:gap-6">
          {COMPETITOR_ROWS.map((r, i) => (
            <React.Fragment key={i}>
              <RowTitle className="styled-font pr-2">{r.name}</RowTitle>
              <Meter className="flex h-full w-full items-center pr-4 md:pr-8">
                <MeterFill width={r.if}>&nbsp;</MeterFill>
                <MeterEmpty width={100 - r.if}>&nbsp;</MeterEmpty>
              </Meter>
              <Meter className="flex h-full w-full items-center pr-4 md:pr-8">
                <MeterFill width={r.c1}>&nbsp;</MeterFill>
                <MeterEmpty width={100 - r.c1}>&nbsp;</MeterEmpty>
              </Meter>
              <Meter className="flex h-full w-full items-center pr-4 md:pr-8">
                <MeterFill width={r.c2}>&nbsp;</MeterFill>
                <MeterEmpty width={100 - r.c2}>&nbsp;</MeterEmpty>
              </Meter>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}
