import * as React from 'react';
import Link from 'components/Link';
import useViewer from 'hooks/useViewer';
import { AuthStatus } from 'constants/auth';
import { hasDashboardAccess, hasAlertAccess } from 'services/client/token';
import LogoImage from 'svg/logo';
import {
  SIGNUP_PAGE,
  LOGIN_PAGE,
  PRICING_PAGE,
  SETTINGS_PAGE,
  OPTION_FLOW_PAGE,
  ALERTS_PAGE,
  HOME_PAGE,
  AFFILIATE_DASHBOARD_PAGE,
  COURSES_INDEX_PAGE,
  OPC_PAGE,
} from 'constants/pages';
import Banner from './Banner';
import TopBanner from 'components/TopBanner';

import {
  Logo,
  Header,
  Content,
  Section,
  LogoLink,
  Container,
  ActionButton,
  TransparentButton,
  NavLink,
  FadeInContent,
} from './styles';

const SHOW_BANNER = false;
const MASTERCLASS_URL = COURSES_INDEX_PAGE;

const Navbar: React.FC<
  React.PropsWithChildren<{
    isStatic?: boolean;
    isTransparent?: boolean;
    hideBanner?: boolean;
    discountBanner?: boolean;
    shouldRequireScrollForDiscount?: boolean;
  }>
> = ({
  isStatic,
  isTransparent,
  hideBanner,
  discountBanner = false,
  shouldRequireScrollForDiscount,
}) => {
  const viewer = useViewer();
  const isDashboardUser = hasDashboardAccess(viewer);
  const isAlertUser = hasAlertAccess(viewer);

  React.useEffect(() => {
    if (viewer.status !== AuthStatus.Loading) {
    }
  }, [viewer.status]);

  return (
    <>
      <Container>
        <Header isStatic={isStatic}>
          {SHOW_BANNER && !hideBanner && <Banner />}
          {discountBanner && <TopBanner shouldShowOnScroll={shouldRequireScrollForDiscount} />}
          <Content
            isStatic={isStatic}
            isTransparent={isTransparent}
            className="flex items-center justify-between"
          >
            <Section>
              <Logo>
                <Link className="shrink-0" href={HOME_PAGE}>
                  <LogoLink>
                    <LogoImage />
                  </LogoLink>
                </Link>
              </Logo>
            </Section>
            <Section className="styled-font">
              {viewer.status === AuthStatus.Anonymous && (
                <FadeInContent>
                  <Link className="shrink-0" href={OPC_PAGE}>
                    <NavLink>Free Options Profit Calculator</NavLink>
                  </Link>
                  <Link className="shrink-0" href={MASTERCLASS_URL}>
                    <NavLink>Free Masterclass</NavLink>
                  </Link>
                  <Link className="shrink-0" href={PRICING_PAGE} passHref>
                    <NavLink>Pricing</NavLink>
                  </Link>
                  <Link className="shrink-0" href={OPTION_FLOW_PAGE} passHref>
                    <NavLink>Options Flow</NavLink>
                  </Link>
                  <Link className="shrink-0" href={LOGIN_PAGE}>
                    <TransparentButton>Log In</TransparentButton>
                  </Link>
                  <Link className="shrink-0" href={SIGNUP_PAGE}>
                    <ActionButton>Sign Up</ActionButton>
                  </Link>
                </FadeInContent>
              )}
              {viewer.status === AuthStatus.User && isDashboardUser && (
                <FadeInContent>
                  <Link className="shrink-0" href={OPC_PAGE}>
                    <NavLink>Options Profit Calculator</NavLink>
                  </Link>
                  <Link className="shrink-0" href={MASTERCLASS_URL}>
                    <NavLink>Free Masterclass</NavLink>
                  </Link>
                  <Link className="shrink-0" href={AFFILIATE_DASHBOARD_PAGE} passHref>
                    <NavLink>Affiliate</NavLink>
                  </Link>
                  <Link className="shrink-0" href={ALERTS_PAGE} passHref>
                    <NavLink>Alerts</NavLink>
                  </Link>
                  <Link className="shrink-0" href={SETTINGS_PAGE} passHref>
                    <NavLink>Settings</NavLink>
                  </Link>
                  <Link className="shrink-0" href={OPTION_FLOW_PAGE}>
                    <ActionButton>Dashboard</ActionButton>
                  </Link>
                </FadeInContent>
              )}
              {viewer.status === AuthStatus.User && isAlertUser && !isDashboardUser && (
                <FadeInContent>
                  <Link className="shrink-0" href={OPC_PAGE}>
                    <NavLink>Options Profit Calculator</NavLink>
                  </Link>
                  <Link className="shrink-0" href={MASTERCLASS_URL}>
                    <NavLink>Free Masterclass</NavLink>
                  </Link>
                  <Link className="shrink-0" href={AFFILIATE_DASHBOARD_PAGE} passHref>
                    <NavLink>Affiliate</NavLink>
                  </Link>
                  <Link className="shrink-0" href={SETTINGS_PAGE} passHref>
                    <NavLink>Settings</NavLink>
                  </Link>
                  <Link className="shrink-0" href={ALERTS_PAGE}>
                    <ActionButton>Alerts</ActionButton>
                  </Link>
                </FadeInContent>
              )}
              {viewer.status === AuthStatus.User && !isAlertUser && !isDashboardUser && (
                <FadeInContent>
                  <Link className="shrink-0" href={OPC_PAGE}>
                    <NavLink>Options Profit Calculator</NavLink>
                  </Link>
                  <Link className="shrink-0" href={MASTERCLASS_URL}>
                    <NavLink>Free Masterclass</NavLink>
                  </Link>
                  <Link className="shrink-0" href={PRICING_PAGE}>
                    <NavLink>Pricing</NavLink>
                  </Link>
                  <Link className="shrink-0" href={AFFILIATE_DASHBOARD_PAGE}>
                    <NavLink>Affiliate</NavLink>
                  </Link>
                  <Link className="shrink-0" href={SETTINGS_PAGE}>
                    <ActionButton>Settings</ActionButton>
                  </Link>
                </FadeInContent>
              )}
            </Section>
          </Content>
        </Header>
      </Container>
    </>
  );
};

export default Navbar;
