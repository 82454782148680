import { useEffect } from 'react';

export default function BottomBanner() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.paritydeals.com/banner.js';
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return <div className="fixed inset-x-0 bottom-0"></div>;
}
