import React from 'react';
import { ArrowRightIcon } from '@heroicons/react/solid';

const GetInTouch = () => {
  return (
    <div className="mx-auto max-w-7xl px-4">
      <div className="mb-12 flex flex-col gap-4 rounded-xl md:items-center md:justify-center lg:flex-row">
        <h2 className="text-left text-lg font-medium md:text-center">
          <span className="text-gray-400">Have a question? We're always available by email.</span>
        </h2>
        <a
          href="mailto:team@insiderfinance.io"
          className="flex items-center justify-center rounded-md bg-white px-4 py-2 font-semibold text-black visited:text-black"
          target="_blank"
        >
          Get in touch <ArrowRightIcon className="ml-2 w-[1.125rem]" />
        </a>
      </div>
    </div>
  );
};

export default GetInTouch;
