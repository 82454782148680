import LandingPage from 'screens/WebSite/LandingPage';
import OptionFlowPage from 'screens/OptionFlowPage';
import { getIsNativePlatform } from 'utils/client/mobile';

const Index = () => {
  if (getIsNativePlatform() || process.env.TARGET_PLATFORM === 'MOBILE') {
    return <OptionFlowPage />;
  } else {
    return <LandingPage />;
  }
};

export default Index;
