import * as React from 'react';
import Link from 'components/Link';
import { XIcon } from '@heroicons/react/outline';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'styles/utils/classNames';
import {
  SIGNUP_PAGE,
  LOGIN_PAGE,
  PRICING_PAGE,
  AFFILIATE_PROGRAM_ARTICLE_PAGE,
  MASTER_CLASS_ARTICLE_PAGE,
  BLOG_INDEX_PAGE,
  DASHBOARD_SECTION,
  TECHNICAL_ANALYSIS_LANDING_PAGE,
  OPTION_FLOW_PAGE,
  OPC_PAGE,
  HOME_PAGE,
} from 'constants/pages';
import useViewer from 'hooks/useViewer';
import { AuthStatus } from 'constants/auth';

interface Props {
  sidebarOpen: boolean;
  setSidebarOpen: (isOpen: boolean) => void;
}

const navigation = [
  {
    name: 'Options Flow',
    href: OPTION_FLOW_PAGE,
  },
  {
    name: 'Complete Trading Toolkit',
    href: `${HOME_PAGE}#${DASHBOARD_SECTION}`,
  },
  { name: 'Options Profit Calculator', href: OPC_PAGE },
  { name: 'Technical Analysis System', href: TECHNICAL_ANALYSIS_LANDING_PAGE },
  { name: 'Pricing', href: PRICING_PAGE },
  { name: 'Affiliate Program', href: AFFILIATE_PROGRAM_ARTICLE_PAGE },
  { name: 'Free Masterclass', href: MASTER_CLASS_ARTICLE_PAGE },
  { name: 'Resources', href: BLOG_INDEX_PAGE },
];

export default function SlideoutMenu({ sidebarOpen, setSidebarOpen }: Props) {
  const viewer = useViewer();
  const isLoading = !viewer || viewer.status === AuthStatus.Loading;
  const isUser = viewer.status === AuthStatus.User;

  return (
    <Transition.Root show={sidebarOpen} as={React.Fragment}>
      <Dialog as="div" className="relative lg:hidden" onClose={setSidebarOpen}>
        <Transition.Child
          as={React.Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 z-40 bg-gray-600 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 flex">
          <Transition.Child
            as={React.Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel
              className="relative flex w-full max-w-xs flex-1 flex-col bg-palette-black-1 focus:outline-none"
              style={{
                paddingTop: 'env(safe-area-inset-top, 0)',
                paddingBottom: 'env(safe-area-inset-bottom, 0)',
              }}
            >
              <Transition.Child
                as={React.Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute right-0 top-0 -mr-12 pt-2">
                  <div className="safearea-pad-top">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </Transition.Child>
              <div className="h-0 flex-1 overflow-y-auto pb-4 pt-5">
                <div className="flex flex-shrink-0 items-center px-4">
                  <img className="h-8 w-auto" src="/images/logo-io.png" alt="Workflow" />
                </div>
                <nav aria-label="Sidebar" className="mt-5">
                  <div className="space-y-1 px-2">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          'text-palette-black-8 hover:bg-palette-black-3 hover:text-palette-black-9',
                          'group flex items-center rounded-md px-2 py-2 text-base font-medium',
                        )}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </nav>
                <div className="mt-6 w-full px-4">
                  {!isLoading &&
                    (isUser ? (
                      <Link
                        className="text-primary block w-full min-w-[6rem] rounded border-2 border-brand-primary bg-brand-primary px-4 py-2.5 text-center font-bold transition-colors hover:border-white hover:bg-white hover:text-brand-primary"
                        href={OPTION_FLOW_PAGE}
                      >
                        Dashboard
                      </Link>
                    ) : (
                      <div className="flex gap-2">
                        <Link
                          href={LOGIN_PAGE}
                          className="text-primary w-1/2 min-w-[6rem] rounded border-2 border-brand-primary bg-transparent px-4 py-2.5 text-center font-bold transition-colors hover:bg-brand-primary"
                        >
                          Log In
                        </Link>
                        <Link
                          href={SIGNUP_PAGE}
                          className="text-primary w-1/2 min-w-[6rem] rounded border-2 border-brand-primary bg-brand-primary px-4 py-2.5 text-center font-bold transition-colors hover:border-white hover:bg-white hover:text-brand-primary"
                        >
                          Sign Up
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
          <div className="w-14 flex-shrink-0" aria-hidden="true">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
