import styled, { css } from 'styled-components';
import { fadeIn } from 'styles/animations';
import {
  RawButton,
  ButtonLink,
  rawButtonStyles,
  ActionButtonLink,
  pageWidthStyles,
  ContainerLarge,
} from 'styles/elements';
import { SIDEBAR_WIDTH } from 'components/Sidebar/styles';
import { mobile, xl } from 'styles/breakpoints';
import { rgba } from 'styles/utils/color';

const sidebarRightStyles = css`
  ${xl()} {
    margin-right: auto;
  }
`;

const sidebarLeftStyles = css`
  ${xl()} {
    margin-left: auto;
  }
`;

export const Spacer = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.spacing.navHeight}px;
`;

export const wrapperStyles = css<{ sidebar?: boolean; right?: boolean; isStatic?: boolean }>`
  ${({ sidebar, right }) => (sidebar ? (right ? sidebarRightStyles : sidebarLeftStyles) : null)};
  width: ${({ sidebar }) => (sidebar ? `calc(100vw - ${SIDEBAR_WIDTH}px)` : '100%')};
  position: ${({ isStatic }) => (isStatic ? 'static' : 'fixed')};
  z-index: ${(props) => props.theme.zIndex.z8};
  top: 0;
  left: 0;

  ${mobile()} {
    width: 100%;
  }
`;

export const contentStyles = css<{ isStatic?: boolean; isTransparent?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${({ theme }) => theme.spacing.navHeight}px;
  padding: 0 ${({ theme }) => theme.spacing.gutter}px;
  background-color: ${({ theme, isTransparent }) =>
    isTransparent ? 'transparent' : rgba(theme.color.navBackground, 0.9)};
`;

export const Header = styled.header<{ isStatic?: boolean; sidebar?: boolean; right?: boolean }>`
  ${wrapperStyles};
  color: ${(props) => props.theme.color.textPrimary};
  z-index: ${(props) => props.theme.zIndex.z8};
  top: 0;
  left: 0;
`;

export const Content = styled.nav<{ isStatic?: boolean; isTransparent?: boolean }>`
  ${pageWidthStyles};
  ${contentStyles};
`;

export const Container = styled(ContainerLarge)``;

export const Section = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: 8px;
  padding-bottom: 8px;

  &:first-child {
    justify-content: flex-start;
  }

  &:last-child {
    justify-content: flex-end;
  }
`;

export const FadeInContent = styled.div`
  opacity: 0;
  animation: ${fadeIn} 500ms linear 1 normal forwards;
  display: flex;
  align-items: center;
  height: 100%;
`;

export const Logo = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin-right: 12px;
  height: ${(props) => props.theme.spacing.navHeight}px;
  font-weight: 700;
  font-size: 20px;
  line-height: 1;

  a {
    display: flex;
    height: 100%;
    align-items: center;
    color: ${({ theme }) => theme.color.brand};
    transition: color 150ms ease, background 150ms ease;

    &:visited {
      color: ${({ theme }) => theme.color.brand};
    }
  }
`;

export const CTAButton = styled(RawButton)`
  color: ${(props) => props.theme.color.textPrimary};
  border: 2px solid ${(props) => props.theme.color.cta};
  border-radius: 2px;
  width: 200px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
  font-size: 0.9em;

  &:hover {
    background-color: ${(props) => props.theme.color.cta};
  }

  &:focus {
    background-color: ${(props) => props.theme.color.cta};
  }
`;

export const UpgradeButton = styled(ActionButtonLink)`
  margin-right: 16px;
`;

export const navItem = css`
  height: ${(props) => props.theme.spacing.navHeight}px;
  display: flex;
  align-items: center;
  margin: 0 8px;
  padding: 0 12px;
  position: relative;
  transition: color 250ms ease;
  line-height: 1;

  svg {
    margin-left: 4px;
    transition: fill 250ms ease;
  }

  &:after {
    background: ${({ theme }) => theme.color.brand};
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0;
    width: 100%;
    transition: height 100ms ease;
  }

  &:hover {
    color: ${({ theme }) => theme.color.brand};

    &:after {
      height: 3px;
    }

    svg {
      fill: ${({ theme }) => theme.color.brand};
    }
  }

  &:focus {
    outline: none;
    color: ${({ theme }) => theme.color.brand};

    &:after {
      height: 3px;
    }

    svg {
      fill: ${({ theme }) => theme.color.brand};
    }
  }
`;

export const NavItem = styled.span<{ displayMobile?: boolean }>`
  ${navItem};

  ${mobile()} {
    display: ${({ displayMobile }) => (displayMobile ? 'flex' : 'none')};
  }
`;

export const AuthButton = styled(RawButton)`
  ${rawButtonStyles};
  ${navItem};
  font-size: 1em;
  color: ${({ theme }) => theme.color.textPrimary};

  ${mobile()} {
    display: none;
  }
`;

export const HideDesktop = styled.div`
  display: none;

  ${mobile()} {
    display: flex;
    align-items: center;
  }
`;

export const LogoLink = styled.span`
  justify-content: center;

  svg {
    height: 28px;
  }
`;

const BUTTON_SPACE = 20;

export const ActionButton = styled(ButtonLink)`
  width: auto;
  font-size: 14px;
  line-height: 1;
  padding: 10px 20px;
`;

export const TransparentButton = styled(ActionButton)`
  background-color: transparent;
  color: ${({ theme }) => theme.color.textPrimary};
  margin-right: ${BUTTON_SPACE}px;

  &:hover {
    border-color: ${({ theme }) => theme.color.buttonPrimary};
    background-color: ${({ theme }) => theme.color.buttonPrimary};
    color: ${({ theme }) => theme.color.textPrimary};
  }

  ${mobile()} {
    display: none;
  }
`;

export const NavLink = styled.span`
  font-weight: 700;
  font-size: 16px;
  margin-right: ${BUTTON_SPACE}px;
  flex-shrink: 0;

  ${mobile()} {
    display: none;
  }
`;
