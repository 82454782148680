import TickerTape from './TickerTape';

const IS_TICKER_ENABLED = false;

export default function TickerTapeBar() {
  if (!IS_TICKER_ENABLED) return null;

  return (
    <div className="relative w-full bg-palette-black-1/95 backdrop-blur-md">
      <TickerTape colorTheme="dark" isTransparent />
    </div>
  );
}
