import styled, { css } from 'styled-components';
import { rgba } from 'styles/utils/color';
import { BasePalette, FOCUS_WIDTH } from 'styles/theme';
import { FONT_FAMILY } from 'styles/GlobalStyle';
import { LARGE_BREAK, tablet } from '../breakpoints';

export const MAX_PAGE_WIDTH = LARGE_BREAK;
export const MAX_CONTENT_WIDTH = 736;
export const MAX_CONTENT_WIDTH_LARGE = 1360;
export const CONTAINER_PAD = 16;
export const PAGE_CONTENT = 1280;

export const centerAlign = css`
  width: 100%;
  margin: 0 auto;
`;

export const CONTAINER_STYLES = css`
  ${centerAlign};
  max-width: ${MAX_CONTENT_WIDTH}px;
`;

export const CONTAINER_STYLES_LARGE = css`
  ${centerAlign};
  max-width: ${MAX_CONTENT_WIDTH_LARGE}px;
`;

export const Container = styled.div`
  ${CONTAINER_STYLES};
`;

export const ContainerLarge = styled.div`
  ${CONTAINER_STYLES_LARGE};
`;

export const pageWidthStyles = css`
  ${centerAlign};
  max-width: ${MAX_PAGE_WIDTH}px;
`;

export const rawButtonStyles = css`
  display: inline-block;
  position: relative;
  border: none;
  text-decoration: none;
  background: none;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: ${FONT_FAMILY};
  color: ${({ theme }) => theme.color.textPrimary};
  line-height: 1;
`;

export const RawButton = styled.button`
  ${rawButtonStyles};
`;

interface BaseButtonProps {
  preventHover?: boolean;
}

const baseButtonStyles = css<BaseButtonProps>`
  width: 100%;
  padding: 11px 20px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.buttonPrimary};
  color: ${({ theme }) => theme.color.textPrimary};
  border-color: ${({ theme }) => theme.color.buttonPrimary};
  font-weight: 700;
  display: inline-block;
  flex: 0 0 auto;
  transition: border-color 0.3s ease, color 0.3s cubic-bezier(0.23, 1, 0.32, 1),
    background-color 0.3s ease;
  border-style: solid;
  border-width: 2px;
  line-height: 22px;

  &:hover {
    border-color: ${({ theme }) => theme.color.textPrimary};
    background-color: ${({ theme }) => theme.color.textPrimary};
    color: ${({ theme }) => theme.color.buttonPrimary};
  }

  &:disabled {
    opacity: 0.4;
    cursor: default;
    background-color: ${({ theme }) => theme.color.buttonPrimary};
    color: ${({ theme }) => theme.color.textPrimary};
    border-color: ${({ theme }) => theme.color.buttonPrimary};
  }
`;

export const Button = styled(RawButton)<BaseButtonProps>`
  ${baseButtonStyles};
`;

export const SolidButton = styled(Button)`
  &:hover {
    color: ${({ theme }) => theme.color.textPrimary};
    background-color: ${({ theme }) => theme.color.buttonHover};
    border-color: ${({ theme }) => theme.color.buttonHover};
  }
`;

export const CancelButton = styled(Button)`
  background-color: ${({ theme }) => theme.color.paper};
  border-color: ${({ theme }) => theme.color.paper};
`;

export const CTAButton = styled(RawButton)<BaseButtonProps>`
  ${baseButtonStyles};
  background-color: ${({ theme }) => theme.color.cta};
  border: 2px solid ${(props) => props.theme.color.cta};

  &:disabled {
    background-color: ${({ theme }) => theme.color.cta};
    border: 2px solid ${(props) => props.theme.color.cta};
    color: ${(props) => props.theme.color.textPrimary};
  }

  &:hover {
    background-color: ${(props) => props.theme.color.textPrimary};
    border-color: ${(props) => props.theme.color.textPrimary};
    color: ${(props) => props.theme.color.cta};
  }

  &:focus {
    background-color: ${(props) => props.theme.color.textPrimary};
    border-color: ${(props) => props.theme.color.textPrimary};
    color: ${(props) => props.theme.color.cta};
  }
`;

export const CTAButtonLink = styled.span`
  ${rawButtonStyles};
  color: ${(props) => props.theme.color.textPrimary};
  border: 2px solid ${(props) => props.theme.color.cta};
  background-color: ${(props) => props.theme.color.cta};
  border-radius: 4px;
  width: 200px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease, border-color 0.2s ease;
  font-size: 0.9em;

  &:hover {
    background-color: ${(props) => props.theme.color.textPrimary};
    border-color: ${(props) => props.theme.color.textPrimary};
    color: ${(props) => props.theme.color.cta};
  }

  &:focus {
    background-color: ${(props) => props.theme.color.textPrimary};
    border-color: ${(props) => props.theme.color.textPrimary};
    color: ${(props) => props.theme.color.cta};
  }
`;

export const ButtonLink = styled.span<BaseButtonProps>`
  ${rawButtonStyles};
  ${baseButtonStyles};
`;

export const BACKGROUND_EASE = 'background 150ms ease';
export const PillButton = styled(RawButton)`
  transition: ${BACKGROUND_EASE};
  background: transparent;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  outline-offset: -${FOCUS_WIDTH}px;

  &:hover {
    background: rgba(80, 80, 80, 0.25);
  }
`;

interface GhostButtonProps {
  color?: string;
  themeColor?: string;
  hoverable?: boolean;
  solid?: boolean;
}

const OPACITY = 0.2;
const getBackground = ({ theme, color = BasePalette.Button, themeColor }: any) => {
  let buttonBackground = BasePalette.Button;

  if (themeColor) {
    buttonBackground = theme.color[themeColor];
  } else if (color) {
    buttonBackground = color;
  }

  return buttonBackground;
};

const ghostButtonStyles = css<GhostButtonProps>`
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${(props) => getBackground(props)};
  background: ${(props) =>
    props.solid ? rgba(getBackground(props), 1) : rgba(getBackground(props), OPACITY)};
  color: ${({ theme }) => theme.color.textPrimary};
  transition: ${BACKGROUND_EASE};

  &:hover {
    background: ${(props) =>
      props.hoverable || props.solid
        ? rgba(getBackground(props), 1)
        : rgba(getBackground(props), OPACITY)};
  }
`;

export const GhostButton = styled(RawButton)<GhostButtonProps>`
  ${ghostButtonStyles};
`;

export const GhostButtonList = styled.span<GhostButtonProps>`
  ${rawButtonStyles}
  ${ghostButtonStyles};
`;

const actionButtonStyles = css`
  line-height: 1;
  color: ${({ theme }) => theme.color.textPrimary};
  background-color: ${({ theme }) => theme.color.alert};
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
  font-size: 0.9em;
  font-weight: 600;
  padding: 6px 12px;

  &:hover {
    background-color: ${({ theme }) => theme.color.alertHover};
  }
`;

export const ActionButtonLink = styled.span`
  ${actionButtonStyles};
`;

export const ActionButton = styled(RawButton)`
  ${rawButtonStyles};
  ${actionButtonStyles};

  &:disabled {
    box-shadow: none;
    background-color: ${({ theme }) => theme.color.alert};
    cursor: default;

    &:hover {
      background-color: ${({ theme }) => theme.color.alert};
    }
  }
`;

export const inputStyles = css<{ isError?: boolean | string }>`
  border: 1px solid ${({ theme, isError }) => (isError ? theme.color.alert : theme.color.border)};
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.15);
  transition: border 150ms ease, box-shadow 150ms ease, outline 150ms ease;
  width: 100%;
  border-radius: 4px;
  color: #323232;
  padding: 12px 16px;
  font-size: 16px;

  &:focus {
    box-shadow: none;
  }
`;

export const newInputSyles = css<{ isError?: boolean | string }>`
  display: block;
  width: 100%;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.42857143;
  color: ${({ theme }) => theme.color.textSecondary};
  background-color: #fff;
  border: 1px solid #ccc;
  height: 48px;
  padding: 10px 16px;
  border: 2px solid transparent;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.paper};
  transition: background-color 0.3s ease, border-color 0.3s ease;
  color: #fff;
  font-weight: 500;

  &:hover {
    border-color: ${({ theme }) => theme.color.buttonPrimary};
  }

  &:focus {
    border-color: ${({ theme }) => theme.color.buttonPrimary};
    background-color: ${({ theme }) => theme.color.backgroundSecondary};
    outline: 0;
  }

  &:disabled {
    opacity: 0.4;
    cursor: default;
    border-color: transparent;
    background-color: ${({ theme }) => theme.color.paper};
  }
`;

export const Input = styled.input<{ isError?: boolean | string }>`
  ${newInputSyles};
`;

export const Textarea = styled.textarea<{ isError?: boolean | string }>`
  ${inputStyles};
`;

export const Label = styled.label`
  display: block;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.4px;
  margin-bottom: 4px;
  font-weight: 600;
`;

export const Select = styled.select`
  background-color: ${({ theme }) => theme.color.paper};
  background-color: ${({ theme }) => theme.color.backgroundSecondary};
  transition: background-color 0.3s ease, border-color 0.3s ease;
  border: 2px solid transparent;
  border-radius: 4px;
  font-size: 12px;
  height: 44px;
  padding: 8px 14px;
  margin-bottom: 10px;

  &:hover {
    border-color: ${({ theme }) => theme.color.buttonPrimary};
  }

  &:focus {
    border-color: ${({ theme }) => theme.color.buttonPrimary};
    background-color: ${({ theme }) => theme.color.backgroundSecondary};
    outline: 0;
  }

  &:disabled {
    opacity: 0.4;
    cursor: default;
    border-color: transparent;
    background-color: ${({ theme }) => theme.color.paper};
  }
`;

export const Grid = styled.div`
  display: flex;
  justify-content: center;

  ${tablet()} {
    flex-direction: column;
  }
`;

export const Column = styled.div<{ width?: number }>`
  width: ${({ width = 50 }) => width}%;
  padding: 0 16px;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }

  ${tablet()} {
    width: 100%;
    padding: 0;
  }
`;

export const FieldWrapper = styled.div`
  margin-bottom: 16px;
  flex: 1;
`;

export const InlineCheckbox = styled.input`
  margin-top: 0;
  margin-right: 10px;
  margin-left: 0;
  flex: 0 0 auto;
  border-style: none;
  background-color: #20222b;
  transition: border-color 0.2s ease, background-color 0.2s ea;
  width: 16px;
  height: 16px;
`;

export const CheckboxInlineLabel = styled.span`
  letter-spacing: 0.4px;
  padding-left: 0;
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  cursor: pointer;
  font-weight: 400;
  margin-bottom: 0;
`;

export const InlineCheckboxWrapperLabel = styled(Label)`
  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-top: 6px;
`;

export const FlatButton = styled(RawButton)<BaseButtonProps>`
  background-color: transparent;
  transition: background-color 250ms ease, border 250ms ease, color 250ms ease;
  color: ${({ theme }) => theme.color.textSecondary};
  border-radius: 4px;
  padding: 12px;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;

  svg {
    fill: currentColor;
    transition: fill 250ms ease;
    margin-left: 4px;
  }

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.color.backgroundPrimary};
    color: ${({ theme }) => theme.color.textPrimary};
  }

  &:focus {
    outline: none;
  }
`;

export const GradientUnderline = styled.div`
  width: 100%;
  height: 2px;
  max-width: 64px;
  margin-right: auto;
  margin-left: auto;
  background-image: linear-gradient(90deg, #00dfd8, #007cf0);
`;
