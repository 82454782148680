import * as React from 'react';
import { FiCheck } from 'react-icons/fi';

export const CommunityPlan = () => {
  const handleClick = () => {
    const mailContentWithSubject =
      'Community%20Plan%20Inquiry&body=Hello%20InsiderFinance%20Team%2C%0D%0A%0D%0A%0D%0AI%20am%20interested%20in%20the%20Community%20Plan%20and%20would%20like%20more%20information.%20Here%20are%20the%20details%20of%20our%20group%3A%0D%0A%0D%0A%0D%0ANumber%20of%20non-professional%20(retail)%20users%3A%20%5BInsert%20number%20here%5D%0D%0A%0D%0ANumber%20of%20professional%20(institutional)%20users%3A%20%5BInsert%20number%20here%5D%0D%0A%0D%0AWebsite%20(if%20applicable)%3A%20%5BInsert%20website%20here%5D%0D%0A%0D%0AAdditional%20information%3A%20%5BAny%20other%20relevant%20details%20or%20questions%5D%0D%0A%0D%0A%0D%0AWe%20look%20forward%20to%20hearing%20from%20you%2C%0D%0A%0D%0A%5BYour%20Name%5D%0D%0A';
    const mailtoLink = `mailto:team@insiderfinance.io?subject=${mailContentWithSubject}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className="px-5 py-10">
      <div className="mx-auto max-w-4xl">
        <h2 className="my-4 text-center text-2xl font-bold sm:text-3xl">Community Plan</h2>
        <div className="border-gradient relative  rounded-3xl bg-gradient-to-r from-blue-500 to-blue-500 p-0.5">
          <div className="relative h-full rounded-3xl bg-gray-900 p-10">
            <p className="mt-6 text-base leading-7">
              Unlock the power of trading together by arming your entire trading community with the
              same powerful data and insights. Our Community Plan puts everyone on the same page,
              creating a powerhouse of shared knowledge and strategies.
            </p>
            <ul role="list" className=" mt-8 space-y-3 text-sm leading-6 sm:mt-10">
              <li className="flex gap-x-3">
                <FiCheck className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                Member Volume Discounts: The more members you add, the less you pay per member.
              </li>
              <li className="flex gap-x-3">
                <FiCheck className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                All-Inclusive Tools: Each member can access our robust dashboard and technical
                analysis system.
              </li>
              <li className="flex gap-x-3">
                <FiCheck className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                Private Discord Channel: Enjoy your own private Discord channel to strategize and
                share insights freely with your community.
              </li>
            </ul>
            <p className="mt-6 text-center text-base leading-7">
              Ready to make collective success your trading strategy?{' '}
            </p>
            <a
              role="button"
              className="mx-auto mt-6 block max-w-xs rounded-md bg-brand-primary px-3 py-4 text-center font-bold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              onClick={handleClick}
            >
              Contact Sales today
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
