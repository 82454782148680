import React, { useState } from 'react';
import { useApiGateway } from 'hooks/useApi';
import classNames from 'styles/utils/classNames';
import * as Sentry from '@sentry/nextjs';

const NEWSLETTER_KEY = 'PaidNewsletter';

export default function NewsLetterBlock() {
  const [email, setEmail] = useState('');
  const { post: newsletterSignupFetch, isLoading, isSuccess } = useApiGateway('/v1/email-list');
  const isDisabled = isLoading;

  return (
    <div className="px-4 py-5">
      <div className="mx-auto max-w-4xl">
        <h4 className="mx-auto mb-1 max-w-md text-center font-space text-5xl font-bold">
          Coming Soon... FREE Trade Ideas Newsletter
        </h4>
        <div className="border-gradient m-auto h-0.5 w-16 bg-gradient-to-r from-blue-500 to-teal-500"></div>
        <div className="relative mt-10 overflow-hidden border-4 border-brand-primary bg-palette-black-4 p-12  px-6 py-10 shadow-2xl sm:rounded-3xl sm:px-24 sm:py-5 xl:py-12">
          <h4 className="mx-auto max-w-2xl text-center text-base font-semibold tracking-tight text-brand-green-primary">
            HIGH POTENTIAL TRADES SENT DIRECTLY TO YOUR INBOX
          </h4>
          <p className="mx-auto mt-2 max-w-xl text-center text-sm font-bold leading-8">
            Add your email to be notified about the free Newsletter launch
          </p>
          {isSuccess ? (
            <div className="mt-10 rounded-2xl bg-white p-4 text-center font-bold text-green-600">
              Success! We'll notify you when the free trade idea newsletter launches.
            </div>
          ) : (
            <form
              onSubmit={async (e) => {
                e.preventDefault();

                if (isDisabled) return;

                try {
                  await newsletterSignupFetch({
                    payload: {
                      email,
                      listId: NEWSLETTER_KEY,
                    },
                  });
                } catch (error) {
                  Sentry.captureException(error);
                } finally {
                  setEmail('');
                }
              }}
              className="mx-auto mt-10 flex w-full flex-col gap-4 md:flex-row"
            >
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className={classNames(
                  'min-w-0 flex-auto rounded-md border-0 bg-white px-3.5 py-2 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6',
                  isDisabled ? 'cursor-not-allowed opacity-50' : '',
                )}
                placeholder="Email..."
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isDisabled}
              />
              <button
                type="submit"
                className={classNames(
                  'w-full flex-none rounded-md bg-brand-primary px-2.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-white hover:text-brand-primary focus-visible:outline focus-visible:outline-2 md:w-48',
                  isDisabled ? 'cursor-not-allowed opacity-50' : '',
                )}
                disabled={isDisabled}
              >
                {isDisabled ? 'Submitting...' : 'Submit'}
              </button>
            </form>
          )}
          <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2"
            aria-hidden="true"
          >
            <circle
              cx={512}
              cy={512}
              r={512}
              fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
              fillOpacity="0.7"
            />
            <defs>
              <radialGradient
                id="759c1415-0410-454c-8f7c-9a820de03641"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(512 512) rotate(90) scale(512)"
              >
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" stopOpacity={0} />
              </radialGradient>
            </defs>
          </svg>
        </div>
        <div className="py-5 text-center">
          Want high potential trade ideas sent directly to your email inbox? InsiderFinance is
          launching a free newsletter that provides a daily summary of options flow, dark pool, news
          sentiment, and more!
        </div>
      </div>
    </div>
  );
}
