import React, { memo } from "react";

import Widget from './Widget';

export type ColorTheme = 'light' | 'dark';
export type DisplayMode = 'regular' | 'compact' | 'adaptive';
export type Locales =
  | 'en'
  | 'in'
  | 'de_DE'
  | 'fr'
  | 'es'
  | 'it'
  | 'pl'
  | 'sv_SE'
  | 'tr'
  | 'ru'
  | 'br'
  | 'id'
  | 'ms_MY'
  | 'th_TH'
  | 'vi_VN'
  | 'ja'
  | 'kr'
  | 'zh_CN'
  | 'zh_TW'
  | 'ar_AE'
  | 'he_IL';

export interface TickerTapeSymbol {
  proName: string;
  title: string;
}

export interface TickerTapeProps {
  symbols?: TickerTapeSymbol[];
  showSymbolLogo?: boolean;
  colorTheme?: ColorTheme;
  isTransparent?: boolean;
  largeChartUrl?: string;
  displayMode?: DisplayMode;
  locale?: Locales;

  children?: never;
}

const defaultSymbols: TickerTapeSymbol[] = [
  {
    proName: 'AMEX:SPY',
    title: 'SPY',
  },
  {
    title: 'QQQ',
    proName: 'NASDAQ:QQQ',
  },
  {
    title: 'Tesla',
    proName: 'NASDAQ:TSLA',
  },
  {
    title: 'Apple',
    proName: 'NASDAQ:AAPL',
  },
  {
    proName: 'BITSTAMP:BTCUSD',
    title: 'Bitcoin',
  },
  {
    proName: 'BITSTAMP:ETHUSD',
    title: 'Ethereum',
  },
  {
    title: 'GameStop',
    proName: 'NYSE:GME',
  },
  {
    title: 'AMC',
    proName: 'NYSE:AMC',
  },
  {
    title: 'Meta',
    proName: 'NASDAQ:FB',
  },
  {
    title: 'NVIDIA',
    proName: 'NASDAQ:NVDA',
  },
  {
    title: 'NIO',
    proName: 'NYSE:NIO',
  },
  {
    proName: 'FOREXCOM:SPXUSD',
    title: 'S&P 500',
  },
  {
    proName: 'FOREXCOM:NSXUSD',
    title: 'US 100',
  },
  {
    proName: 'FX_IDC:EURUSD',
    title: 'EUR/USD',
  },
];

const TickerTape: React.FC<React.PropsWithChildren<TickerTapeProps>> = ({
  symbols = defaultSymbols,
  showSymbolLogo = true,
  colorTheme = 'light',
  isTransparent = false,
  largeChartUrl = undefined,
  displayMode = 'adaptive',
  locale = 'en',
  ...props
}) => {
  return (
    <div id="tradingview_widget_wrapper w-full">
      <Widget
        scriptHTML={{
          symbols,
          showSymbolLogo,
          colorTheme,
          isTransparent,
          largeChartUrl,
          displayMode,
          locale,
          ...props,
        }}
        scriptSRC="https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js"
      ></Widget>
    </div>
  );
};

export default memo(TickerTape);