import { useEffect, useState } from 'react';
import { HiXMark } from 'react-icons/hi2';
import { FcFlashOn } from 'react-icons/fc';

import {
  TIME_DELAY,
  SHOW_BANNER_CONDITION,
  DEAL_TEXT,
  DISCOUNT_TEXT,
  BUTTON_TEXT,
  TIME_LEFT,
  SHOW_CLOSE_ICON,
  // BANNER_TIMER_STORAGE_KEY,
  TARGET_DATE,
  IS_USE_TARGET_DATE,
} from 'constants/topBannerConfig';
import { PRICING_SECTION, PRICING_PAGE } from 'constants/pages';

import { useShowBannerConditions } from 'hooks/useShowBanner';
// import useBrowserStorage from 'hooks/useBrowserStorage';

export default function TopBanner({
  showCloseIcon = SHOW_CLOSE_ICON,
  shouldShowOnScroll,
}: {
  showCloseIcon?: boolean;
  shouldShowOnScroll?: boolean;
}) {
  const showBanner = useShowBannerConditions({
    timeDelay: TIME_DELAY,
    additionalCondition: SHOW_BANNER_CONDITION,
    showOnScroll: shouldShowOnScroll,
  });

  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const initialTimeLeft = IS_USE_TARGET_DATE
    ? Math.floor((new Date(TARGET_DATE).getTime() - new Date().getTime()) / 1000)
    : TIME_LEFT;
  const [timeLeft, setTimeLeft] = useState(initialTimeLeft);
  // const [timeLeft, setTimeLeft] = useBrowserStorage<number>(
  //   BANNER_TIMER_STORAGE_KEY,
  //   TIME_LEFT,
  //   'localStorage',
  // );

  const handleCloseBanner = () => {
    setIsBannerVisible(false);
  };

  const formatTime = (seconds: number) => {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);
    const secs = seconds % 60;
    return (
      <div className="flex items-center gap-x-2">
        <span className="hidden items-center gap-2 px-1 md:flex">Offer expires in</span>
        <span className="flex h-8 w-10 items-center justify-center rounded-lg bg-white p-1 px-1 text-center font-bold text-black">
          {days}d
        </span>
        :
        <span className="flex h-8 w-10 items-center justify-center rounded-lg bg-white p-1 px-1 text-center font-bold text-black">
          {hours}h
        </span>
        :
        <span className="flex h-8 w-10 items-center justify-center rounded-lg bg-white p-1 px-1 text-center font-bold text-black">
          {minutes}m
        </span>
        :
        <span className="flex h-8 w-10 items-center justify-center rounded-lg bg-white p-1 px-1 text-center font-bold text-black">
          {secs}s
        </span>
      </div>
    );
  };

  useEffect(() => {
    if (showBanner) {
      const intervalId = setInterval(() => {
        if (IS_USE_TARGET_DATE) {
          const now = new Date();
          const targetDate = new Date(TARGET_DATE);
          const differenceInSeconds = Math.floor((targetDate.getTime() - now.getTime()) / 1000);

          setTimeLeft(() => {
            if (differenceInSeconds <= 0) {
              clearInterval(intervalId);
              return 0;
            }
            return differenceInSeconds;
          });
        } else {
          setTimeLeft((prevTime: number) => {
            if (prevTime <= 0) {
              clearInterval(intervalId);
              return 0;
            }
            return prevTime - 1;
          });
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [showBanner, setTimeLeft]);

  if (!showBanner || !isBannerVisible) return null;

  return (
    <div className="relative isolate flex flex-col items-center justify-center gap-2 overflow-hidden bg-brand-primary px-2 py-2.5 text-sm md:flex-row md:gap-4">
      <div className="text-md flex items-center text-white">
        <div className="flex items-center">
          <span className="hidden items-center font-semibold lg:flex">
            <FcFlashOn className="h-6 w-6" />
            {DEAL_TEXT} <FcFlashOn className="h-6 w-6" />
          </span>
          <span className="px-2">{DISCOUNT_TEXT}</span> &nbsp;
        </div>
        <span className="hidden md:flex">👉</span>
      </div>
      <div className="flex items-center gap-2">
        <span className="text-md">{formatTime(timeLeft)}</span>
        <a
          href={`${PRICING_PAGE}#${PRICING_SECTION}`}
          className="ml-2 flex min-h-8 items-center rounded-md bg-brand-green-secondary px-3.5 py-1 text-sm font-semibold text-white no-underline shadow-sm hover:bg-white hover:text-brand-green-secondary"
        >
          {BUTTON_TEXT}
        </a>
      </div>
      {showCloseIcon && (
        <div className="absolute bottom-0 right-4 top-0 my-auto flex items-center ">
          <button
            type="button"
            className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
            onClick={handleCloseBanner}
          >
            <span className="sr-only">Dismiss</span>
            <HiXMark aria-hidden="true" className="h-6 w-6 text-white" />
          </button>
        </div>
      )}
    </div>
  );
}
