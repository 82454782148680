import { useEffect, useState } from 'react';
import useViewer from 'hooks/useViewer';
import { AuthStatus } from 'constants/auth';
import { useHasAccess } from './useHasAccess';
import { TIME_DELAY } from 'constants/topBannerConfig';

type Props = {
  timeDelay?: number;
  showOnScroll?: boolean;
  scrollThreshold?: number;
  additionalCondition?: () => boolean;
};

export const useShowBannerConditions = ({
  timeDelay = TIME_DELAY,
  showOnScroll = true,
  scrollThreshold = typeof window !== 'undefined' ? window.innerHeight : 0,
  additionalCondition = () => true,
}: Props = {}) => {
  const viewer = useViewer();
  const isLoading = !viewer || viewer.status === AuthStatus.Loading;
  const { shouldHaveDashboardAccess: hasAccess } = useHasAccess();
  const [hasTimeElapsed, setHasTimeElapsed] = useState(false);
  const [hasScrolledEnough, setHasScrolledEnough] = useState(false);
  const shouldShow =
    hasTimeElapsed && (hasScrolledEnough || !showOnScroll) && additionalCondition();

  useEffect(() => {
    if (isLoading || hasAccess) return;

    const handleScroll = () => {
      if (window.scrollY >= scrollThreshold) {
        setHasScrolledEnough(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    const timer = setTimeout(() => {
      setHasTimeElapsed(true);
      handleScroll();
    }, timeDelay);

    return () => {
      clearTimeout(timer);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isLoading, hasAccess, timeDelay, showOnScroll, scrollThreshold, additionalCondition]);

  return shouldShow;
};
