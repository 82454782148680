export default function Logo({ className }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 770.05 131.3">
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.cls-1{fill:#fff;}.cls-2{fill:#a3e5c4;}.cls-3{fill:#73c19b;}.cls-4{fill:#22bd69;}.cls-5{fill:#0f8947;}.cls-6{fill:#5dd098;}.cls-7{fill:#46bc80;}.cls-8{fill:#2fa769;}.cls-9{fill:#199450;}',
          }}
        />
      </defs>
      <title>InsiderFinance</title>
      <g id="Calque_2">
        <g id="Calque_1-2">
          <path className="cls-1" d="M128.64,26.2h12.45V92.71H128.64Z" />
          <path
            className="cls-1"
            d="M186.51,68.2c0-7.13-3.9-12.54-11.31-12.54-7.13,0-12,6-12,13.11V92.71h-11.5V45.78h10.36l.76,6.36c4.75-4.65,9.5-7,15.39-7,11,0,19.86,8.27,19.86,23v24.6H186.51Z"
          />
          <path
            className="cls-1"
            d="M227.08,54.14c-5.51,0-8.56,1.71-8.56,4.65s2.76,4.75,8.75,5.13c8.83.58,20,2.57,20,15,0,8.26-6.74,15.39-20.14,15.39-7.41,0-14.82-1.24-21.66-8.36l5.7-8.27c3.32,3.71,10.93,6.46,16.15,6.56,4.37.09,8.46-2.19,8.46-5.61,0-3.23-2.66-4.56-9.31-4.94-8.84-.66-19.39-3.89-19.39-14.54,0-10.83,11.22-14.63,19.77-14.63,7.31,0,12.82,1.43,18.24,6.18l-6.46,7.6C235.34,55.18,231.54,54.14,227.08,54.14Z"
          />
          <path
            className="cls-1"
            d="M267.36,32.66c0,9-13.68,9-13.68,0S267.36,23.64,267.36,32.66ZM254.72,92.71h11.59v-47H254.72Z"
          />
          <path
            className="cls-1"
            d="M325.41,92.71H314.58l-.76-6.46c-3.61,5.61-9.4,7.51-15.11,7.51-13.77,0-24.13-9.12-24.13-24.51,0-16.16,10.17-24.52,23.85-24.52,4.94,0,12.64,2.66,15.39,7.51V26.3h11.59ZM286.17,69.25c0,8.17,5.89,13.77,13.3,13.77a13.28,13.28,0,0,0,13.59-13.77,13.37,13.37,0,0,0-13.59-13.69C292.06,55.56,286.17,60.79,286.17,69.25Z"
          />
          <path
            className="cls-1"
            d="M360.37,83.4c4.28,0,9.89-1.61,12.55-4.37l7.41,7.32c-4.94,5.13-13,7.6-20.15,7.6C344,94,334.44,84,334.44,69c0-14.25,9.69-24.51,24.89-24.51,15.68,0,25.46,9.69,23.66,29H346.41C347.17,79.22,352.2,83.4,360.37,83.4Zm-.66-28.69c-6.27,0-11.4,3-13.11,9.12h25.27C371.11,57.75,366.36,54.71,359.71,54.71Z"
          />
          <path
            className="cls-1"
            d="M403.51,51.29c3.61-5.8,8.45-6.65,13.21-6.65s9.5,1.9,12.06,4.46l-5.22,10.07a11.61,11.61,0,0,0-8.36-3c-6.09,0-11.69,3.23-11.69,11.88v24.7H391.92V45.87h10.73Z"
          />
          <path
            className="cls-1"
            d="M478.85,57.84v4.85H440.56V92.81h-5.13V26.2h44.75v5.13H440.56V57.84Z"
          />
          <path
            className="cls-1"
            d="M496.81,30.48c0,4.94-7.51,4.94-7.51,0S496.81,25.54,496.81,30.48Zm-6.18,62.23h4.66V45.59h-4.66Z"
          />
          <path
            className="cls-1"
            d="M513.15,54.14c3.61-6.46,10.17-9.12,16.72-9.22,12.54,0,21.19,7.7,21.19,20.72V92.71H546.4v-27c0-10.64-6.55-16.34-16.72-16.25-9.69.1-16.44,7.41-16.44,17.11V92.71h-4.65V45.78h4.56Z"
          />
          <path
            className="cls-1"
            d="M608.35,92.71H603.7v-10a22,22,0,0,1-19.2,10.73c-13.11,0-23.85-9.88-23.85-24.22s10.74-24,23.85-24c7.6,0,15.3,3.51,19.2,10.83V45.78h4.65Zm-43-23.46c0,11.78,8.55,19.66,19.19,19.66,26.13,0,26.13-39.24,0-39.24C573.86,49.67,565.31,57.46,565.31,69.25Z"
          />
          <path
            className="cls-1"
            d="M625.55,54.14c3.61-6.46,10.16-9.12,16.72-9.22,12.54,0,21.19,7.7,21.19,20.72V92.71H658.8v-27c0-10.64-6.55-16.34-16.72-16.25-9.69.1-16.44,7.41-16.44,17.11V92.71H621V45.78h4.57Z"
          />
          <path
            className="cls-1"
            d="M697,93.19c-13.2,0-24.13-9.12-24.13-23.94s10.55-24,24.13-24a24.44,24.44,0,0,1,17.11,7l-3,3A19.92,19.92,0,0,0,697,49.67c-10.64,0-19.57,6.94-19.57,19.58S686.35,88.82,697,88.82A19.88,19.88,0,0,0,711,83l3.14,3.14A24,24,0,0,1,697,93.19Z"
          />
          <path
            className="cls-1"
            d="M722.83,69.25c0-14.07,10.65-24.23,23.85-24.23s25,8.07,23.19,26.41H727.59c.95,11,9.4,17.39,19.09,17.39,6.18,0,13.4-2.47,16.92-7.13l3.32,2.66c-4.56,6-12.73,9-20.24,9C733.48,93.38,722.83,84.16,722.83,69.25Zm23.85-20c-9.69,0-18,6.56-19.09,18.06h38.1C765.59,55.75,758,49.29,746.68,49.29Z"
          />
          <path
            className="cls-2"
            d="M0,128.24v3.06a59.86,59.86,0,0,1,16.42-18.81v-3.06A60.08,60.08,0,0,0,0,128.24Z"
          />
          <path
            className="cls-3"
            d="M25.07,104.08v3a60.59,60.59,0,0,1,22.73-6.3V97.77A60.42,60.42,0,0,0,25.07,104.08Z"
          />
          <path
            className="cls-3"
            d="M57,97.77v3.06a60.45,60.45,0,0,1,22.3,6.09v-3A60.66,60.66,0,0,0,57,97.77Z"
          />
          <path
            className="cls-3"
            d="M88.23,109.28v3a59.79,59.79,0,0,1,14.86,16v-3.05A59.79,59.79,0,0,0,88.23,109.28Z"
          />
          <path
            className="cls-3"
            d="M0,125.71v2.53a60.08,60.08,0,0,1,16.42-18.81V106.9A60.08,60.08,0,0,0,0,125.71Z"
          />
          <path
            className="cls-4"
            d="M25.07,104.08A60.42,60.42,0,0,1,47.8,97.77V95.24a60.42,60.42,0,0,0-22.73,6.31Z"
          />
          <path
            className="cls-4"
            d="M57,97.77a60.66,60.66,0,0,1,22.3,6.1v-2.53A60.46,60.46,0,0,0,57,95.24Z"
          />
          <path
            className="cls-4"
            d="M88.23,106.75v2.53a59.79,59.79,0,0,1,14.86,16v-2.53A59.64,59.64,0,0,0,88.23,106.75Z"
          />
          <path
            className="cls-5"
            d="M16.42,101.41v5.49A60,60,0,0,0,0,125.7v-5.48A59.85,59.85,0,0,1,16.42,101.41Z"
          />
          <path
            className="cls-5"
            d="M47.8,89.75v5.5a60.5,60.5,0,0,0-22.74,6.3V96.07A60.35,60.35,0,0,1,47.8,89.75Z"
          />
          <path
            className="cls-5"
            d="M79.34,95.87v5.48A60.22,60.22,0,0,0,57,95.25v-5.5A60.63,60.63,0,0,1,79.34,95.87Z"
          />
          <path
            className="cls-5"
            d="M103.09,117.31v5.48a59.65,59.65,0,0,0-14.85-16.05v-5.48A59.65,59.65,0,0,1,103.09,117.31Z"
          />
          <path
            className="cls-6"
            d="M16.42,65.78v35.63A59.85,59.85,0,0,0,0,120.22V103.89A191.16,191.16,0,0,1,16.42,65.78Z"
          />
          <path
            className="cls-7"
            d="M47.89,25.78V36.87q0,26.45-.09,52.88a60.35,60.35,0,0,0-22.74,6.32v-44A162.41,162.41,0,0,1,47.8,25.87.26.26,0,0,0,47.89,25.78Z"
          />
          <path
            className="cls-8"
            d="M57,18.07l-.1.07L57,89.75a60.63,60.63,0,0,1,22.3,6.12V4.58A105.72,105.72,0,0,0,57,18.07Z"
          />
          <path
            className="cls-9"
            d="M103.09.58V117.31a59.65,59.65,0,0,0-14.85-16V1.47C94.1-.13,99.2-.42,103.09.58Z"
          />
        </g>
      </g>
    </svg>
  );
}
